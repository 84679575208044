// /*--------------------------
//     Navigation Styles 
// ----------------------------*/
// .mainmenu-nav {
//     .mainmenu {

//         &.nav-not-sticky {
//             > li {
//                 > a {
//                     color: var(--color-light); // Color for non-sticky state
//                     transition: color 0.3s ease;
//                 }
//             }
//         }
        
//         &.nav-sticky {
//             > li {
//                 > a {
//                     color: var(--color-body); // Color for sticky state
//                 }
//             }
//         }

//         display: flex;
//         margin: 0;
//         padding: 0;
//         > li {
//             > a {
//                 color: var(--color-body);
//                 font-size: 16px;
//                 font-weight: 500;
//                 padding: 0 17px;
//                 display: block;
//                 height: 100px;
//                 line-height: 100px;
//                 transition: 0.3s;
//                 &:hover {
//                     color: var(--color-primary);
//                 }
//             }
//         }
//         li {
//             margin-top: 0;
//             margin-bottom: 0;
//             position: relative;

//             &.has-droupdown {
//                 .submenu {
//                     min-width: 240px;
//                     height: auto;
//                     position: absolute;
//                     top: 90%;
//                     left: 0;
//                     z-index: 90;
//                     opacity: 0;
//                     visibility: hidden;
//                     text-align: left;
//                     padding: 12px 0;
//                     transition: 0.3s;
//                     border-radius: 0 0 10px 10px;
//                     background-color: var(--color-blackest);
//                     box-shadow: var(--shadow-lighter);
//                     li {
//                         a {
//                             font-weight: 500;
//                             padding: 5px 20px;
//                             font-size: 14px;
//                             display: block;
//                             color: var(--color-body);
//                             margin: 0 10px;
//                             border-radius: 3px;
//                             @extend %transition;
//                             &:hover {
//                                 color: var(--color-primary);
//                                 background: var(--color-darker);
//                             }
//                         }
//                     }
//                 }
    
//                 &:hover {
//                     .submenu {
//                         opacity: 1;
//                         visibility: visible;
//                         top: 100%;
//                     }
//                 }
//             }

//             &.with-megamenu { 
//                 position: static;

//                 .rn-megamenu {
//                     position: absolute;
//                     transition: 0.3s;
//                     top: 90%;
//                     width: 100%;
//                     left: 0;
//                     right: 0;
//                     padding: 0 15px;
//                     visibility: hidden;
//                     opacity: 0;
//                     .wrapper {
//                         border-radius: 0 0 10px 10px;
//                         background-color: var(--color-blackest);
//                         box-shadow: var(--shadow-lighter);

//                         .mega-menu-item {
//                             padding: 22px 10px;
//                             border-right: 1px solid var(--color-extra04);
//                             height: 100%;

//                             li {
//                                 a {
//                                     font-weight: 500;
//                                     padding: 5px 14px;
//                                     font-size: 15px;
//                                     display: block;
//                                     color: var(--color-body);
//                                     margin: 0 10px;
//                                     border-radius: 4px;
//                                     transition: 0.3s;
//                                     display: flex;
//                                     align-items: center;

//                                     .rn-badge-card {
//                                         margin-left: 10px;
//                                     }

//                                     &:hover {
//                                         color: var(--color-primary);
//                                         background: var(--color-darker);
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 .single-mega-item {
//                     &:last-child {
//                         .mega-menu-item {
//                             border-right: 0 none;
//                         }
//                     }
//                 }

//                 &:hover {
//                     .rn-megamenu {
//                         opacity: 1;
//                         visibility: visible;
//                         top: 100%;
//                     }
//                 }
//             }

//         }
//     }
// }

.mainmenu-nav {
    .mainmenu {
        display: flex;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;

        // Sticky Navigation Styles
        &.nav-not-sticky {
            > li {
                > a {
                    color: var(--color-light);
                    transition: color 0.3s ease;
                }
            }
        }
        
        &.nav-sticky {
            > li {
                > a {
                    color: var(--color-body);
                }
            }
        }

        > li {
            > a {
                font-size: clamp(14px, 1vw, 16px);
                font-weight: 500;
                padding: 0 clamp(10px, 1.5vw, 17px);
                display: block;
                height: 100px;
                line-height: 100px;
                transition: 0.3s;
                white-space: nowrap;
                
                &:hover {
                    color: var(--color-primary);
                }
            }
        }

        // Mobile styles
        &.mobile-menu {
            > li {
                > a {
                    padding: 0 10px;
                    font-size: 14px;
                    height: 80px;
                    line-height: 80px;
                }
            }
        }

        // Responsive Styles
        @media screen and (max-width: 1400px) {
            > li > a {
                padding: 0 12px;
                font-size: 14px;
            }
        }

        @media screen and (max-width: 1200px) {
            > li > a {
                padding: 0 10px;
                font-size: 13px;
                height: 80px;
                line-height: 80px;
            }
        }

        // Your existing dropdown styles
        li {
            margin-top: 0;
            margin-bottom: 0;
            position: relative;

            &.has-droupdown {
                .submenu {
                    min-width: 240px;
                    height: auto;
                    position: absolute;
                    top: 90%;
                    left: 0;
                    z-index: 90;
                    opacity: 0;
                    visibility: hidden;
                    text-align: left;
                    padding: 12px 0;
                    transition: 0.3s;
                    border-radius: 0 0 10px 10px;
                    background-color: var(--color-blackest);
                    box-shadow: var(--shadow-lighter);

                    @media screen and (max-width: 1200px) {
                        min-width: 200px;
                    }

                    li {
                        a {
                            font-weight: 500;
                            padding: 5px 20px;
                            font-size: 14px;
                            display: block;
                            color: var(--color-body);
                            margin: 0 10px;
                            border-radius: 3px;
                            transition: 0.3s;
                            &:hover {
                                color: var(--color-primary);
                                background: var(--color-darker);
                            }
                        }
                    }
                }
    
                &:hover {
                    .submenu {
                        opacity: 1;
                        visibility: visible;
                        top: 100%;
                    }
                }
            }
        }
    }
}